<template>
  <div class="box picDetails" :style="'height:' + offsetHeight + 'px'">
    <!--<div class="top">-->
    <!--<img src="../assets_ch/backb.png" alt="" @click="back">-->
    <!--返回-->
    <!--</div>-->
    <div class="top" v-show="isShowInfo">
      <div class="top-container">
        <!-- <img src="../assets_ch/backb.png" alt="" @click="back" /> -->
        <div class="text-box">
          <p>
            {{ folder.folderName }}（{{ Number(index) + 1 }}/{{
              picList.length
            }}）
          </p>
          <p class="date">{{ date }}</p>
        </div>
      </div>
    </div>
    <van-swipe
      class="my-swipe"
      :show-indicators="false"
      :initial-swipe="index"
      @change="swiperChange"
    >
      <van-swipe-item v-for="(image, index) in picList" :key="index">
        <div class="swipe-container">
          <div class="swipe-img">
            <div @click="handleClickImage">
              <van-image width="100%" fit="cover" :src="image.readCultureImg">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
          </div>
          <div class="desc" ref="desc">{{ image.imgDescribe }}</div>
          <div class="swipe-intro" v-show="isShowInfo">
            <template v-if="image.isShowIntro">
              <div
                class="swipe-info-hidden"
                @click="handleTarget(image, index)"
              >
                <img
                  class="swipe-info-img"
                  fit="cover"
                  :src="imagesAddressAddressZh+'/img/hidden.png'"
                  v-if="image.isShowIcon"
                />

                {{ image.imgDescribe }}
              </div>
            </template>
            <template v-else>
              
              <div :class="image.isShowIcon ? 'swipe-info' : 'swipe-info-hidden'" @click="handleTarget(image, index)">
                  <img
                  class="swipe-info-img"
                  fit="cover"
                  :src="imagesAddressAddressZh+'/img/show.png'"
                  v-if="image.isShowIcon"
                />
                {{ image.imgDescribe }}
                
              </div>
            </template>
            <div class="button">
              <div
                class="button-item"
                @click="viewOriginal(image, index)"
                v-if="!image.isOriginal"
              >
                <img :src="imagesAddressAddressZh+'/checkImgDetails.png'" alt="" />
              </div>
              <div
                class="button-item"
                v-show="isShowDownload"
                @click="downLoadImage(image, index)"
              >
                <img class="button-border" :src="imagesAddressAddressZh+'/img/button-border.png'" alt="">
                <img class="button-item-icon" :src="imagesAddressAddressZh+'/img/download.png'" alt="" />
                <span>下载图片</span>
              </div>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { Image as VanImage, Toast } from "vant";
import { enumsImages } from "@/utils/enum.js";
import $ from "jquery";
export default {
  name: "picDetails",
  components: { VanImage },
  data() {
    return {
      show: false,
      saveShow: false,
      obj: {},
      pic: 0,
      index: "",
      date: "",
      picList: [],
      folder: [],
      isShowDownload: true,
      isShowInfo: true,
      offsetHeight: '',
      // folder:[],
      // img: '',
      // details: '',
      // time: ''
      imagesAddressAddressZh:enumsImages.addressZh,
      
    };
  },
  created() {
    // console.log(document.body.offsetHeight);
    this.offsetHeight = document.body.clientHeight
    this.folderId = this.$route.query.foldId;
    //console.log('000',this.$route.query.foldId)
    //console.log('this.$route.query.picList',this.$route.query.picList)

    this.index = this.$route.query.index;
    // this.obj.readCultureImg = this.$route.query.img
    // this.obj.imgDescribe = this.$route.query.details
    this.obj = JSON.parse(this.$route.query.picList);
    this.obj.addTime = this.obj.addTime.substr(0, 10);
    this.date = this.obj.addTime;
  },
  mounted() {
    this.getPicList();
    this.judgeApp()
  },
  methods: {
    // 判断是否是app内部
    judgeApp() {
      // 需要ios 安卓传递参数
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        try {
          let isApp = window.Android.isApp();
          if (isApp == "android_yuanzilian") {
            this.isShowDownload = true;
          } else {
            this.isShowDownload = false;
          }
        } catch (err) {
          this.isShowDownload = false;
        }
      }
      if (isiOS) {
        try {
          //1、通知IOS 传参数; isApp ios方法
          window.webkit.messageHandlers.isApp.postMessage({});
          window.isApp = this.isApp;
        } catch (err) {
          this.isShowDownload = false;
        }
      }
      // console.log(this.isShowDownload)
    },
    //获取ios
    isApp: function (value) {
      if (value == "ios_yuanzilian") {
        this.isShowDownload = true;
      } else {
        this.isShowDownload = false;
      }
    },
    handleClickImage() {
      this.isShowInfo = !this.isShowInfo;
    },
    showImg() {
      this.show = true;
    },
    swiperChange(index) {
      this.index = index;
      this.date = this.picList[index].addTime.substr(0, 10);
    },
    back() {
      history.go(-1);
    },
    // 根据相册ID获取图片列表
    getPicList() {
      //文字区域最大高度
      const textHeight = (document.body.clientWidth / 750) * 100 * 1.38;

      //调取接口
      let that = this,
        data = {
          cultureFolderId: this.folderId
        },
        url = "https://official.juhaowu.cn/api/cultureFolder/listByCultureFolderId",
        url_en = "https://en.atoshi.info/api/cultureFolder/listByCultureFolderId"
      // server.listByCultureFolderId({
      //     cultureFolderId: this.folderId,
      //   })
      //   .then((res) => {
      //     that.picList = res.data.data.cultures.map((item, index) => {
      //       item.isOriginal = false;
      //       item.isShowIntro = false;
      //       item.isShowIcon = false;
      //       return item;
      //     });
      //     that.$nextTick(() => {
      //       let descList = that.$refs.desc
      //       that.picList = that.picList.map((item,index) => {
      //         if(descList[index].offsetHeight > textHeight){
      //           item.isShowIcon = true
      //         }
      //         return item
      //       })
      //     })
      //     //console.log('3333',that.picList[1])
      //     that.folder = res.data.data.cultureFolder;
      //     // that.$nextTick(()=>{
      //     //   console.log(that.$refs)
      //     // })
      //   });
      $.ajax({
        type: "post", //提交方式
        url: that.$store.state.isEn ? url_en : url, //路径
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data), // 数据
        success: function (res) {
          //返回数据根据结果进行相应的处理
          if (res.code == 100) {
            let resData = res.data;
            
            that.picList = resData.cultures.map((item) => {
              item.isOriginal = false;
              item.isShowIntro = false;
              item.isShowIcon = false;
              return item;
            });
            that.$nextTick(() => {
              let descList = that.$refs.desc
              that.picList = that.picList.map((item,index) => {
                if(descList[index].offsetHeight > textHeight){
                  item.isShowIcon = true
                }
                return item
              })
            })
            that.folder = resData.cultureFolder;
          }
        },
      });
    },
    viewOriginal(item, index) {
      this.picList[index].readCultureImg = item.primaryCultureImg;
      this.picList[index].isOriginal = true;
    },
    downLoadImage(item) {
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("Android") > -1) {
        window.Android.downloadVideo(
          item.primaryCultureImg,
          item.imgDescribe,
          "zh"
        );
      } else if (/^.+(Mobile\/\w+)\s?$/.test(userAgent)) {
        // let datas=JSON.stringify({fxtitle:_this.videotitle,fxurl:_this.videoUrl,fxcoverimg:_this.imgurl})
        // window.webkit.messageHandlers.shareNewsToiPhone.postMessage(datas);
      }
    },
    handleTarget(item, index) {
      this.picList[index].isShowIntro = !this.picList[index].isShowIntro;
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  background: #000000;
}

.top {
  width: 100%;
  height: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.top .top-container {
  position: relative;
}
.top img {
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  top: 50%;
  left: 0.3rem;
  transform: translateY(-50%);
}

.top .text-box {
  width: 7.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.top .text-box p {
  text-align: center;
  font-size: 0.36rem;
  color: #fff;
}

.top .text-box .date {
  font-size: 0.24rem;
  color: #999;
}

.my-swipe {
  width: 100%;
  height: 100%;
}

.swipe-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.swipe-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swipe-intro {
  width: 100%;
  /* max-height: 2.48rem; */
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
}
.swipe-info {
  width: 100%;
  max-height: 1.38rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: #fff;
}
.swipe-info-hidden {
  width: 100%;
  /* height: 1.38rem; */
  color: #fff;
}
.swipe-info::before {
  content: "";
  float: right;
  width: 0;
  height: 100%;
  margin-bottom: -0.3rem;
}
.swipe-info-hidden::before {
  content: "";
  float: right;
  width: 0;
  height: 100%;
  margin-bottom: -0.3rem;
}
.swipe-info-img {
  width: 0.3rem;
  height: 0.3rem;
  float: right;
  clear: both;
}
.desc {
  position: fixed;
  width: 7.5rem;
  left: -7.5rem;
  z-index: -1;
}
.button {
  width: 100%;
  height: 0.7rem;
  margin: 0.2rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.button-original {
  justify-content: center;
}
.button .button-item {
  width: 2.54rem;
  height: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  /* background: url('../assets_ch/img/button-border.png') no-repeat center; */
  /* border: .01rem solid #fff; */
  border-radius: .5rem;
}

.button .button-item img {
  width: 2.54rem;
  height: 0.7rem;
}

.button .button-item .button-border{
  position: absolute;
  top: 0;
  left: 0;
}

.button .button-item .button-item-icon{
  width: .33rem;
  height: .32rem;
  margin-right: .17rem;
}


/* .save{
  width: 100%;
  height: 2rem;
  background-color: white;
}

.save .save-item{
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.save .blue{
    background-color: #3f9ce8;
    color: white;
} */
</style>
